.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 800px;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db4437;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.google-logo {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.google-text {
  font-weight: bold;
}



.facebook-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1877F2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.facebook-logo {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.facebook-text {
  font-weight: bold;
}

.email-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  /* height: 40px; */
}

.email-text {
  font-weight: bold;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.centeredFlexColumn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexRowCentered{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ptw-text {
  font-weight: 800;
  padding: 0;
  margin: 0;
  background: -webkit-linear-gradient(right, #f49610 0%, #f0533a 100%);
  /* background: -webkit-linear-gradient(right, #23c461 0%, #0fa5e7 100%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 3s slide-left;
  animation-iteration-count:1;
}

.slide-right {
  animation: 3s slide-right;
  animation-iteration-count:1;
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}


.a-gray{

  text-decoration-color:'white'

}

.descrip0 {
  padding: 0;
  margin: 0;
  background: -webkit-linear-gradient(right, #94ea5d 0%, #36AA3E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.descrip1 {

  padding: 0;
  margin: 0;
  background: -webkit-linear-gradient(right, #e74a9c 0%, #3c81f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.descrip2 {

  padding: 0;
  margin: 0;
  background: -webkit-linear-gradient(right, #a25af4 0%, #31d0c1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.descrip3 {

  padding: 0;
  margin: 0;
  background: -webkit-linear-gradient(right, #9c73f0 0%, #fda421 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.descrip4 {

  padding: 0;
  margin: 0;
  background: -webkit-linear-gradient(right, #e0e01d 0%, #94ea5d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.descrip5 {

  padding: 0;
  margin: 0;
  background: -webkit-linear-gradient(right, #f49610 0%, #f0533a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.btn-grad0{
  background: linear-gradient(-45deg,#033146, #21718a)
}





.teams-clubs-text {
  font-weight: 800;
  /* font-size: 35px; */
  padding: 0;
  margin: 0;
  /* color: white; */
  /* background: -webkit-linear-gradient(right, #e74a9c 0%, #3c81f5 100%); */
  background: -webkit-linear-gradient(right, #23c461 0%, #0fa5e7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.explain-text {
  font-weight: 800;
  /* font-size: 35px; */
  padding: 0;
  margin: 0;
  /* background: -webkit-linear-gradient(right, #23c461 0%, #0fa5e7 100%); */
  color: '#1f2022';
  /* background: -webkit-linear-gradient(-45deg,#033146, #21718a); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
}

.card {
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 14px #00000029;
  border-radius: 5px;
}

.card p {
  font-weight: bold;
  margin: 0px 0px 5px 0px;
}


.card2 {
  background-color: white;
  padding: 15px;
  text-align: left;
  box-shadow: 0 4px 14px #00000029;
  border-radius: 5px;
}

.card2 p {
  margin: 0px;
  padding: 0px;
}


.slide-left {
  animation: 3s slide-left;
  animation-iteration-count:1;
}
@keyframes slide-left {
  from {
    margin-right: -100%;
  }
  to {
    margin-right: 0%;
  }
}


.logo-wrap {
  position: relative;
}

.logo-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.22;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/amped-games.appspot.com/o/logo.gif?alt=media&token=dc4531cd-d549-4e4b-b98b-25e12de52c0f');
  background-repeat: no-repeat;
  background-position: center;
  /* background-size:45%; */
  background-size: contain;
}

.logo-wrap-content {
  position: relative;
}


.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}


.stats-card {
  height: 100px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 14px #00000029;
  border-radius: 5px;
  display: flex;
  height:75px;
  flex-direction: row;
  justify-content: space-around;

  /* background: linear-gradient(-45deg, #E9FAE0, #E9FAE0, #BFFBC6, #96FAB5, #93FBC4, #E9FAE0, #E9FAE0, #E9FAE0); */
  /* background: rgb(2,0,36); */
   /* background: rgb(102,100,255);  */
  /* background: linear-gradient(90deg, rgba(102,100,255,1) 0%, rgba(116,55,255,0.6033773016997167) 100%); */
  /* background: linear-gradient(90deg, #6d8dff, #6a7dfb); */
  /* background: linear-gradient(90deg, #a25af4 0%, #31d0c1); */
  background: linear-gradient(-160deg, #ffffff, #F1F1F1);



  box-shadow: 0 6px 15px #00000029;
  border-radius: 5px;

}



.wager-card {
  height: 100px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 14px #00000029;
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-around;

  border-width: 1px;
  border-style: solid dashed;
  border-image: linear-gradient(to right, #ff1744 50%, #36AA3E 50%);
  border-image-slice: 1;



}



.top-gradient {
	background: linear-gradient(225deg, #2c2d2e,#1f2022,#1f2022,#181819);
}



.animated-gradient {
  background: linear-gradient(-45deg,#16051F, #16051F, #400439, #69054A, #6C043B, #16051F, #16051F, #16051F);
  background-size: 350% 350%;
	animation: gradient 30s ease infinite;
}

@keyframes gradient {
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.animated-gradient-2 {
  background: linear-gradient(-45deg, #3C1053, #3C1053, #AD5389, #3C1053, #642A7F, #3C1053, #3C1053, #3C1053);
  background-size: 350% 350%;
  animation: gradient-2 30s ease infinite;
}

@keyframes gradient-2 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.floating-button {
  position: fixed;
  bottom: 30px;
  /* right: 20px; */
  right:0px;
  z-index: 100; /* Ensure it sits on top of everything else */
}


.play-button {
  background: linear-gradient(-45deg,#23c461, #0fa5e7);
}



.collapsible-header {
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.chevron {
  display: inline-block;
  transition: transform 0.3s;
}

.chevron.open {
  transform: rotate(90deg);
}

.collapsible-content {
  /* margin-left: 30px;  adjust as needed */
  padding: 5px 15px;
  padding-left: 8px;
  transition: max-height 0.3s, opacity 0.3s;
  max-height: 100vh;  /* max height, will grow up to this value */
  opacity: 1;
}

/* Optional, if you want smooth collapse/expand effect */
.collapsible-item:not(:last-child) {
  margin-bottom: 12px;
}


@keyframes shimmering {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}

.c-skeleton {
  display: inline-block;
  height: 20px;
  width: 100%;
  background: linear-gradient(90deg, 
                              rgba(210, 210, 210, 0.5), 
                              rgba(230, 230, 230, 0.5), 
                              rgba(210, 210, 210, 0.5));
  background-size: 200% 100%;
  animation: shimmering 1.5s infinite;
}

.flashing-bulb {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  animation: flash 1s infinite;
}

@keyframes flash {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@keyframes pulse {
  /* 0%, 100% { color: #e0e0e0; } 
  50% { color: #bdbdbd; }  */
  0%, 100% { color: white; } /* Light gray */
  50% { color: gray; } /* Darker gray */
}

.pulsing {
  animation: pulse 2s infinite;
  color: transparent; /* Hide text color */
}
